import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createSectionEditorialRichTextProjection } from '../sections/createSectionEditorialRichTextProjection'
import { createSectionQuoteProjection } from '../sections/createSectionQuoteProjection'
import { createSectionNextProjectProjection } from '../sections/createSectionNextProjectProjection'
import { createSectionCardSliderProjection } from '../sections/createSectionCardSliderProjection'
import { createSectionProjectCardProjection } from '../sections/createSectionProjectCardProjection'
import { createSectionTextBlockWithLinkProjection } from '../sections/createSectionTextBlockWithLinkProjection'
import { createSectionContactProjection } from '../sections/createSectionContactProjection'
import { createSectionIntroTextsProjection } from '../sections/createSectionIntroTextsProjection'
import { createSectionHomeHeroProjection } from '../sections/createSectionHomeHeroProjection'
import { createSectionTextBlocksProjection } from '../sections/createSectionTextBlocksProjection'
import { createSectionVideoTestimonialsProjection } from '../sections/createSectionVideoTestimonialsProjection'
import { createSectionProjectCardGridProjection } from '../sections/createSectionProjectCardGridProjection'
import { createSectionSingleMediaWithDescriptionProjection } from '../sections/createSectionSingleMediaWithDescriptionProjection'
import { createSectionArticlesLandingPageProjection } from '../sections/createSectionArticlesLandingPageProjection'
import { createSectionArticleChapterProjection } from '../sections/createSectionArticleChapterProjection'
import { createSectionArticleAuthorProjection } from '../sections/createSectionArticleAuthorProjection'
import { createSectionDoubleMediaWithBackgroundProjection } from '../sections/createSectionDoubleMediaWithBackgroundProjection'
import { createSectionSideTextBlockProjection } from '../sections/createSectionSideTextBlockProjection'
import { createSectionServicesProjection } from '../sections/createSectionServicesProjection'
import { createSectionCaseStudyCardsProjection } from '../sections/createSectionCaseStudyCards'
import { createSectionServicesListingProjection } from '../sections/createSectionServicesListingProjection'
import { createSectionTechstackSliderProjection } from '../sections/createSectionTechstackSliderProjection'
import { createSectionNewsletterProjection } from '../sections/createSectionNewsletterProjection'
import { createSectionSocialMediaEmbedProjection } from '../sections/createSectionSocialMediaEmbedProjection'
import { createSectionFeaturedBlogPostsProjection } from '../sections/createSectionFeaturedBlogPostsProjection'
import { createSectionTermlyProjection } from '../sections/createSectionTermlyProjection'
import { createSectionNewsletterV2Projection } from '../sections/createSectionNewsletterV2Projection'
import { createSectionShouldIUseHydrogenProjection } from '../sections/createSectionShouldIUseHydrogenProjection'

export const createPageBuilderProjection = (localeId: LocaleId) => groq`{
  _type == 'sectionEditorialRichText' =>  ${createSectionEditorialRichTextProjection(
    localeId,
  )},
  _type == 'sectionQuote' =>  ${createSectionQuoteProjection(localeId)},
  _type == 'sectionCardSlider' =>  ${createSectionCardSliderProjection(
    localeId,
  )},
  _type == 'sectionNextProject' =>  ${createSectionNextProjectProjection(
    localeId,
  )},
  _type == 'sectionProjectCard' =>  ${createSectionProjectCardProjection(
    localeId,
  )},
  _type == 'sectionTextBlockWithLink' =>  ${createSectionTextBlockWithLinkProjection(
    localeId,
  )},
  _type == 'sectionContact' =>  ${createSectionContactProjection(localeId)},
  _type == 'sectionIntroTexts' =>  ${createSectionIntroTextsProjection(
    localeId,
  )},
  _type == 'sectionHomeHero' =>  ${createSectionHomeHeroProjection(localeId)},
  _type == 'sectionTextBlocks' =>  ${createSectionTextBlocksProjection(
    localeId,
  )},
  _type == 'sectionProjectCardGrid' =>  ${createSectionProjectCardGridProjection(
    localeId,
  )},
  _type == 'sectionVideoTestimonials' =>  ${createSectionVideoTestimonialsProjection(
    localeId,
  )},
  _type == 'sectionArticlesLandingPage' =>  ${createSectionArticlesLandingPageProjection(
    localeId,
  )},
  _type == 'sectionArticleChapter' =>  ${createSectionArticleChapterProjection(
    localeId,
  )},
  _type == 'sectionArticleAuthor' =>  ${createSectionArticleAuthorProjection(
    localeId,
  )},
  _type == 'sectionSingleMediaWithDescription' =>  ${createSectionSingleMediaWithDescriptionProjection(
    localeId,
  )},
  _type == 'sectionDoubleMediaWithBackground' =>  ${createSectionDoubleMediaWithBackgroundProjection(
    localeId,
  )},
  _type == 'sectionSideTextBlock' => ${createSectionSideTextBlockProjection(
    localeId,
  )},
  _type == 'sectionServices' => ${createSectionServicesProjection(localeId)},
  _type == 'sectionCaseStudyCards' => ${createSectionCaseStudyCardsProjection(
    localeId,
  )},
  _type == 'sectionServicesListing' => ${createSectionServicesListingProjection(
    localeId,
  )},
  _type == 'sectionTechstackSlider' => ${createSectionTechstackSliderProjection(
    localeId,
  )},
  _type == 'sectionNewsletter' =>  ${createSectionNewsletterProjection(
    localeId,
  )},
  _type == 'sectionSocialMediaEmbed' =>  ${createSectionSocialMediaEmbedProjection(
    localeId,
  )},
  _type == 'sectionFeaturedBlogPosts' =>  ${createSectionFeaturedBlogPostsProjection(
    localeId,
  )},
  _type == 'sectionTermly' =>  ${createSectionTermlyProjection()},
  _type == 'sectionNewsletterV2' =>  ${createSectionNewsletterV2Projection(
    localeId,
  )},
  _type == 'sectionShouldIUseHydrogen' =>  ${createSectionShouldIUseHydrogenProjection(
    localeId,
  )},
}`
