import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionShouldIUseHydrogenProjection = (
  localeId: LocaleId,
) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('subtitle', localeId)},
        ${coalesceLocaleField('ctaLabel', localeId)},
        ctaLink${LINK_PROJECTION},
        media${MEDIA_PROJECTION}
      }
  `
}
