import { LinkFieldType } from '@portfolio/models'
import { fonts, styled } from '@portfolio/styles'
import { Button } from '../Button/Button'
import { Cross } from '@portfolio/icons'
import { Link } from '../Link/Link'
import { useState, useEffect } from 'react'

const ANNOUNCEMENT_BAR_STORAGE_KEY = 'announcementBarClosed'

type AnnouncementBarProps = {
  announcementBar: {
    title?: string
    ctaLabel?: string
    ctaLabelMobile?: string
    cta?: LinkFieldType
  }
  theme: 'transparent-dark' | 'transparent-light'
}

const AnnouncementBarWrapper = styled('div', {
  padding: '$12 $32',
  textAlign: 'center',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  transition:
    'transform 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out',
  transform: 'translateY(0)',
  opacity: 1,
  variants: {
    theme: {
      'transparent-dark': {
        backgroundColor: '$gray50',
        color: '$darkThemeBackground',
      },
      'transparent-light': {
        backgroundColor: '$gray600',
        color: '$white',
      },
    },
  },
  defaultVariants: {
    theme: 'transparent-dark',
  },

  '&.hidden': {
    transform: 'translateY(-100%)',
    opacity: 0,
  },
})

const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$24',
})

const Text = styled('p', {
  ...fonts.body05,
  display: 'none',
  variants: {
    theme: {
      'transparent-dark': {
        color: '$gray600',
      },
      'transparent-light': {
        color: '$white',
      },
    },
  },
  defaultVariants: {
    theme: 'transparent-dark',
  },

  '@lg': {
    display: 'block',
  },
})

const StyledLink = styled(Link, {
  ...fonts.body05,
  textUnderlineOffset: '.35em',
  variants: {
    theme: {
      'transparent-dark': {
        color: '$gray600',
      },
      'transparent-light': {
        color: '$white',
      },
    },
  },
  defaultVariants: {
    theme: 'transparent-dark',
  },
})

const StyledButton = styled(Button, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$rMax',
  transition: '0.2s background',
  padding: '$6',
  variants: {
    theme: {
      'transparent-dark': {
        background: '$black_005',
        color: '$darkThemeBackground',
        '@md': {
          '&:hover': {
            background: '$gray600_01',
          },
        },
      },
      'transparent-light': {
        background: '$white_005',
        color: '$white',
        '@md': {
          '&:hover': {
            background: '$black_01',
          },
        },
      },
    },
  },
  defaultVariants: {
    theme: 'transparent-dark',
  },

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },

  '&:disabled': {
    display: 'none',
  },
})

const DesktopCTALabel = styled('span', {
  display: 'none',

  '@lg': {
    display: 'block',
  },
})

const MobileCTALabel = styled('span', {
  display: 'block',

  '@lg': {
    display: 'none',
  },
})

export const AnnouncementBar = ({
  announcementBar,
  theme,
}: AnnouncementBarProps) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const isClosed = sessionStorage.getItem(ANNOUNCEMENT_BAR_STORAGE_KEY)
    if (isClosed === 'true') {
      setIsVisible(false)
    }
  }, [])

  const handleClose = () => {
    setIsVisible(false)
    sessionStorage.setItem(ANNOUNCEMENT_BAR_STORAGE_KEY, 'true')
  }

  if (!isVisible) return null

  return (
    <AnnouncementBarWrapper
      className={!isVisible ? 'hidden' : ''}
      theme={theme}
    >
      <ContentWrapper>
        <Text theme={theme}>{announcementBar.title}</Text>
        {announcementBar.cta && announcementBar.ctaLabel && (
          <StyledLink
            appearance="textUnderline"
            {...announcementBar.cta}
            theme={theme}
          >
            <DesktopCTALabel>{announcementBar.ctaLabel}</DesktopCTALabel>
            <MobileCTALabel>{announcementBar.ctaLabelMobile}</MobileCTALabel>
          </StyledLink>
        )}
      </ContentWrapper>
      <StyledButton
        appearance="default"
        size="small"
        onClick={handleClose}
        theme={theme}
        aria-label="Close announcement"
      >
        <Cross />
      </StyledButton>
    </AnnouncementBarWrapper>
  )
}
