export const Cross = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none">
      <path
        fill="currentColor"
        d="m3.915 4.792 3.182 3.182.708-.708-3.182-3.182L7.889.818 7.182.111 3.915 3.377.733.195.026.903l3.182 3.181L.111 7.182l.707.707 3.097-3.097Z"
      />
    </svg>
  )
}
