const FONT_INTL_REGULAR = {
  fontFamily: 'var(--font-suisse-intl-regular), sans-serif',
  fontWeight: 400,
}
const FONT_INTL_BOOK = {
  fontFamily: 'var(--font-suisse-intl-book), sans-serif',
  fontWeight: 400,
}

const FONT_INTL_MONO = {
  fontFamily: 'var(--font-suisse-intl-mono), sans-serif',
  fontWeight: 400,
}

const FONT_WORKS_REGULAR = {
  fontFamily: 'var(--font-suisse-works-regular), sans-serif',
  fontWeight: 400,
}

const FONT_WORKS_MEDIUM = {
  fontFamily: 'var(--font-suisse-works-medium), sans-serif',
  fontWeight: 400,
}

export const fonts = {
  display01: {
    lin: {
      fontSize: [70, 134],
    },
    lineHeight: 1.0,
    letterSpacing: '-0.04em',
    ...FONT_INTL_REGULAR,
  },
  display02: {
    lin: {
      fontSize: [52, 70],
    },
    lineHeight: 1.0,
    letterSpacing: '-0.04em',
    ...FONT_INTL_REGULAR,
  },
  heading01: {
    lin: {
      fontSize: [34, 62],
    },
    lineHeight: 1.1,
    letterSpacing: '-0.03em',
    ...FONT_INTL_REGULAR,
  },
  heading02: {
    fontSize: 52,
    lin: {
      fontSize: [34, 52],
      lineHeight: [1.1, 1.2],
    },
    lineHeight: 1.2,
    letterSpacing: '-0.03em',
    ...FONT_INTL_REGULAR,
  },
  heading03: {
    lin: {
      fontSize: [18, 36],
    },
    lineHeight: 1.2,
    letterSpacing: '0.04em',
    ...FONT_INTL_REGULAR,
  },
  heading04: {
    lin: {
      fontSize: [24, 36],
    },
    lineHeight: 1.2,
    ...FONT_INTL_REGULAR,
  },
  heading05: {
    lin: {
      fontSize: [28, 34],
    },
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
    ...FONT_INTL_BOOK,
  },
  heading06: {
    lin: {
      fontSize: [24, 30],
    },
    lineHeight: 1.2,
    '@md': {
      lineHeight: 1.4,
    },
    ...FONT_WORKS_REGULAR,
  },
  heading07: {
    lin: {
      fontSize: [20, 24],
    },
    lineHeight: 1.2,
    ...FONT_INTL_BOOK,
  },
  heading08: {
    fontSize: 18,
    ...FONT_INTL_BOOK,
  },
  heading09: {
    ...FONT_WORKS_REGULAR,
    lineHeight: 1.2,
    lin: {
      fontSize: [34, 52],
    },
  },
  body01: {
    lin: {
      fontSize: [17, 20],
    },
    lineHeight: 1.6,
    ...FONT_WORKS_REGULAR,
  },
  body01_bold: {
    lin: {
      fontSize: [17, 20],
    },
    lineHeight: 1.6,
    ...FONT_WORKS_MEDIUM,
  },
  body02: {
    fontSize: 17,
    lineHeight: 1.6,
    ...FONT_WORKS_REGULAR,
  },
  body03: {
    fontSize: 17,
    lineHeight: 1.4,
    ...FONT_INTL_REGULAR,
  },
  body04: {
    fontSize: 15,
    lineHeight: 1.4,
    ...FONT_INTL_REGULAR,
  },
  body05: {
    fontSize: 14,
    lineHeight: 1.2,
    ...FONT_INTL_REGULAR,
  },
  ui01: {
    fontSize: 16,
    lineHeight: 1.4,
    letterSpacing: '0.02em',
    ...FONT_INTL_BOOK,
  },
  ui02: {
    fontSize: 16,
    lineHeight: 1.4,
    ...FONT_INTL_REGULAR,
  },
  ui03: {
    fontSize: 12,
    lineHeight: 2.0,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    ...FONT_INTL_BOOK,
  },
  ui04: {
    fontSize: 12,
    lineHeight: 2.0,
    ...FONT_INTL_REGULAR,
  },
  ui05: {
    fontSize: 14,
    lineHeight: 2.0,
    ...FONT_INTL_REGULAR,
  },
  ui06: {
    fontSize: 12,
    lineHeight: 1.4,
    ...FONT_INTL_MONO,
  },
  numbers01: {
    lin: {
      fontSize: [62, 96],
    },
    lineHeight: 1,
    ...FONT_INTL_REGULAR,
  },
}

export type FontType = keyof typeof fonts

export const getFontStyles = (fontType: FontType) => fonts[fontType]
