import { groq } from 'next-sanity'
import { createLinkProjection } from './createLinkProjection'

export const createAnnouncementBarProjection = (localeId: string) => {
  return groq`
    {
      title,
      ctaLabel,
      ctaLabelMobile,
      cta${createLinkProjection(localeId)},
    }[0]
  `
}
