export const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.72 1.573H3.584v-1h7.843V8.27h-1V2.28L1.28 11.427l-.707-.707L9.72 1.573Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
