import { createBaseImageProjection } from './projections/createBaseImageProjection'
import { getLocaleRegionIdFromPath } from '@portfolio/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { SiteConfiguration } from '@portfolio/models'
import { createSEOProjection } from './projections/createSEOProjection'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { DEFAULT_REGION_ID } from '@portfolio/configs'
import { createLinkProjection } from './projections/createLinkProjection'
import { createRichTextProjection } from './projections/createRichTextProjection'
import { createAnnouncementBarProjection } from './projections/createAnnouncementBarProjection'

export const getSiteConfiguration = async (
  localeRegionString: string,
  preview = false,
): Promise<SiteConfiguration> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const ANNOUNCEMENT_BAR_PROJECTION = createAnnouncementBarProjection(localeId)

  const seoQuery = groq`
    *[_type == "defaultSeo"]{
      defaultSeo${createSEOProjection(localeId)}
    }[0].defaultSeo
    `

  const announcementBarQuery = groq`
    *[_type == "announcementBar"]
      ${ANNOUNCEMENT_BAR_PROJECTION}
  `

  const socialsQuery = groq`
    *[_type == "socials"]{
      items[]{
        url,
        logoLight${BASE_IMAGE_PROJECTION},
        logoDark${BASE_IMAGE_PROJECTION},
      }
    }[0]
  `

  const headerQuery = groq`
  *[_type == "header"] {
    "headerNavigation": coalesce(headerNavigation_${regionId}, headerNavigation_${DEFAULT_REGION_ID}) -> {
      navLinks[] {
        ${coalesceLocaleField('ctaLabel', localeId)},
        ctaLink${LINK_PROJECTION},
        ctaIcon${BASE_IMAGE_PROJECTION},
      },
      "socials":${socialsQuery}
    }
  }[0].headerNavigation
`

  const footerQuery = groq`
  *[_type == "footer"] {
    "footerNavigation": coalesce(footerNavigation_${regionId}, footerNavigation_${DEFAULT_REGION_ID}) -> {
        info{
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField('text', localeId)},
          contact->{
            ${coalesceLocaleField('email', localeId)},
          }
        },
        joinUs{
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${RICH_TEXT_PROJECTION},
          ctaLink${LINK_PROJECTION},
          ${coalesceLocaleField('ctaLabel', localeId)}
        },
        newsletter{
          ${coalesceLocaleField('title', localeId)},
          hubspotFormPortalId,
          hubspotFormId,
          ${coalesceLocaleField(
            'privacyPolicyNotice',
            localeId,
          )}[]${RICH_TEXT_PROJECTION},
        },
        bottomInfo[]{
          itemType,
          ${coalesceLocaleField('ctaLabel', localeId)},
          ${coalesceLocaleField('text', localeId)},
          ctaLink${LINK_PROJECTION},
        },
        "socials":${socialsQuery}
      }
  }[0].footerNavigation
`

  const siteConfigurationQuery = groq`{
      "header": ${headerQuery},
      "footer": ${footerQuery},
      "seo": ${seoQuery},
      "announcementBar": ${announcementBarQuery},
    }
  `

  const siteConfigurationData = await getClient(preview).fetch(
    siteConfigurationQuery,
  )
  return {
    ...siteConfigurationData,
  }
}
